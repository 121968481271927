import React from 'react';
import './css/index.css';

import camoLogoImage from './img/WEB1.png';
// import axLogoImage from './ax-logo-image.png';
import telegram from './img/WEB3.png';
import twitterImage from './img/WEB2.png';
import instagramImage from './img/WEB4.png';

function App() {
	return (
		<div className="App">
			<p className="trade-text">TRADE LIKE A PRO WITH</p>
			<img src={camoLogoImage} alt="CAMO Logo" className="camo-logo" />
			<p className="docs-guide-text">DOCS GUIDE</p>
			<div className="social-images">
				<a className="social-image" href="https://t.me/avaxcamo"><img src={telegram} alt="Telegram" /></a>
				<a className="social-image" href="https://twitter.com/AvaxCamo"><img src={twitterImage} alt="Twitter" /></a>
				<a className="social-image" href="http://docs.avbot.xyz"><img src={instagramImage} /></a>
			</div>
		</div>
	);
}

export default App;
